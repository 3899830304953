/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  font-family: Asap, sans-serif !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-user-select: text; /* Safari */
  -ms-user-select: text; /* IE 10 and IE 11 */
  user-select: text; /* Standard syntax */
}

.search .slick-list {
  padding: 0 50px 0 0 !important;
}

.waca-bg-primary {
  background-color: #CC1F1F;
}

.waca-color-primary {
  color: #CC1F1F;
}

.waca-ai-bg-primary {
  background-color: #520292;
}

.waca-ai-color-primary {
  color: #520292;
}

.btn-height {
  height: 40px;
}

.max-w-500 {
  max-width: 500px !important;
}


.w-loader, .w-loader:before, .w-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.w-loader {
  color: #CC1F1F;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.w-loader:before,
.w-loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.w-loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.w-loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em
  }
  40% {
    box-shadow: 0 2.5em 0 0
  }
}

